import React from "react"
import "./ApaceTheme.css"

const ApaceTheme = () => {
  return (
    <div className="apace_theme_container">
      <div className="theme_img_container">
        {/* <img src="/images/logo.png" alt="logo" /> */}
      </div>
    </div>
  )
}

export default ApaceTheme
