import React, { useState } from "react"
import { ThemeProvider } from "@mui/material/styles"
import theme from "../theme"
import ApaceTheme from "../components/ApaceTheme/ApaceTheme"
import Nav2 from "../components/Layout/Navbar"
import Footer from "../components/Layout/Footer"
import HubspotForm from "react-hubspot-form"
import "../styles/home.scss"

const isBrowser = typeof window !== "undefined"

const about = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  if (isBrowser) {
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 50) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Nav2 />
      <div className="w-full bg-white">
        <div className="container py-[100px] pt-[200px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 2xl:gap-20">
            <div className="mb-10 md:mb-0">
              <h1 className="text-[#0d83b9] leading-[42px] text-[35px] 2xl:text-[65px] 2xl:leading-[80px] font-normal mb-[60px]">
                Apace empowers
                <br />
                you to stay on pace.
              </h1>
              <p className=" text-lg leading-[30px] md:text-[20px] lg:text-[22px] font-normal text-dark lg:leading-[34px] mb-[65px] lg:pr-[30px]">
                In a hyper-instant world, shoppers no longer accept a wait of
                days or weeks to see their refunds post to their card or bank
                account.
                <br />
                <br />
                Apace is the bridge solution that pays them without delay, and
                then collects the original refund from you, the retailer.
                <br />
                <br />
                INNOVATIVE. YET SIMPLE.
              </p>
            </div>
            <div>
              <h1 className=" text-2xl leading-[32px] md:text-[28px] md:leading-[34px] lg:text-[30px] lg:leading-[44px] 2xl:text-[40px] 2xl:leading-[54px] font-normal  text-black mb-[60px]">
                Download the Apace brochure
              </h1>
              <HubspotForm
                portalId="21879558"
                formId="a8659b13-35a5-41e1-bd03-b78ab26b5484"
                onSubmit={() => console.log("Submit!")}
                onReady={form => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </div>

        <ApaceTheme />
      </div>
      <Footer />
    </ThemeProvider>
  )
}

export default about
